import * as React from 'react'

import { Link } from 'gatsby'
import useSound from 'use-sound'

// @ts-ignore
import hoverSfx from '../../../static/sounds/ESM_Puzzle_Game_Organic_Wood_Block_Tone_Tap_4_App_Click_Cartoon_App_Mobile_FX_Menu_Tap_Touch_Slide_Switch.wav'

interface IPropTypes {
  path: string
  label: string
}

const NavLink = ({ path, label }: IPropTypes) => {
  const [playHoverSound] = useSound(hoverSfx)

  return (
    <li className="w-min py-2" key={label}>
      <Link
        className="text-primary special-underline"
        activeClassName="decoration-[#12cf7e] decoration-4 underline underline-offset-4 underline-thickness-8"
        to={path}
        onMouseEnter={() => playHoverSound()}
      >
        <p className="font-bold special-underline">{label}</p>
      </Link>
    </li>
  )
}

export default NavLink
