import { Link } from 'gatsby'
import React from 'react'

import LogoDark from '../../assets/LogoDark.svg'

const Logo = () => {
  return (
    <div className="flex-initial">
      <Link to="/">
        <img className="w-12 h-12" src={LogoDark} />
      </Link>
    </div>
  )
}

export default Logo
