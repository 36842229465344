import * as React from 'react'

import { MenuIcon, XIcon } from '@heroicons/react/solid'

import NavLink from './NavLink'
import Logo from '../Logo'

import navLinks from '../../../data/navLinks.json'
import DarkModeToggle from '../../buttons/DarkModeToggle'

import useOutsideAlerter from '../../../utils/hooks/useOutsideAlterter'
import SoundModeToggle from '../../buttons/SoundModeToggle'

// TODO: implement site wide search
const Navigation = () => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const mobileMenuRef = React.useRef(null)

  useOutsideAlerter(mobileMenuRef, () => setOpenMenu(false))

  return (
    <div
      className={`${
        openMenu ? 'fixed' : 'absolute'
      } bg-primary lg:bg-transparent top-0 left-0 z-20 flex-initial w-full text-primary border-t-4 border-[#12CF7E]`}
    >
      <div className="max-w-3xl mx-auto py-4" ref={mobileMenuRef}>
        {/* DEKSTOP MENU */}
        <div className="hidden lg:flex mx-6">
          <div className="flex-initial">
            <Logo />
          </div>
          <div className="flex-grow flex h-12">
            <div className="ml-8 flex-grow">
              <ul className="flex space-x-6 h-full">
                {navLinks.map((navLink) => (
                  <div
                    className="h-full flex items-center align-center"
                    key={navLink.label}
                  >
                    <NavLink path={navLink.path} label={navLink.label} />
                  </div>
                ))}
              </ul>
            </div>
            <div className="flex-initial flex justify-end align-center items-center h-full gap-x-4">
              <SoundModeToggle />
              <DarkModeToggle />
            </div>
          </div>
        </div>

        {/* MOBILE MENU */}
        <div className="flex lg:hidden mx-6">
          <div className="flex-initial">
            <Logo />
          </div>
          <div className="flex-grow flex justify-end gap-4 align-center items-center">
            <SoundModeToggle />
            <DarkModeToggle />
            <button
              aria-label={!openMenu ? 'Open Menu' : 'Close Menu'}
              onClick={() => setOpenMenu(!openMenu)}
            >
              {openMenu ? (
                <XIcon className={`text-primary w-6 h-6`} />
              ) : (
                <MenuIcon className={`text-primary w-6 h-6`} />
              )}
            </button>
          </div>
        </div>
        {openMenu && (
          <div className="lg:hidden z-10 inset-0 w-full overflow-hidden mx-6 mt-8 h-full">
            <ul className="flex flex-col justify-end gap-y-3">
              {navLinks.map((navLink) => (
                <div key={navLink.label}>
                  <NavLink path={navLink.path} label={navLink.label} />
                </div>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navigation
