import * as React from 'react'

import AuroraBackground from '../animation/AuroraBackground'
import Navigation from './navigation/Navigation'
import Footer from './Footer'
import CookieNotice from '../Cookies/CookieNotice'
import SEO from './SEO'

interface IPropTypes {
  title?: string
  description?: string
  children: React.ReactNode
  aurora?: boolean
}

// TODO: create an optional page title prop that makes the title format consistent across the entire site
// TODO: create SEO component based on this
const Layout = ({ title, description, children, aurora }: IPropTypes) => {
  return (
    <div className="text-primary min-h-screen overflow-hidden">
      <SEO
        title={title ? title : 'Yannis Panagis'}
        description={
          description ? description : 'Engineer. Designer. Developer. Creator'
        }
      />
      <Navigation />
      <div className="flex flex-col max-w-3xl mx-auto relative">
        {aurora && <AuroraBackground />}
        <div className="mt-24">{children}</div>
        <Footer />
      </div>
      <CookieNotice />
    </div>
  )
}

export default Layout
