import * as React from 'react'

import { Link } from 'gatsby'

import Emoji from '../Emojis/Emoji'
import { useSoundModeContext } from '../../context/sound-context'

const Footer = () => {
  const { soundMode, playPopSound } = useSoundModeContext()

  const helpfulLinks = [
    { link: 'Home', to: '/' },
    { link: 'About', to: '/about' },
    { link: 'Projects', to: '/projects' },
    { link: 'Blog', to: '/blog' },
    { link: 'Uses', to: '/uses' },
    { link: 'About this site', to: '/about-this-site' },
  ]

  const contactLiknks = [
    { link: 'GitHub', to: 'https://github.com/yp717' },
    { link: 'LinkedIn', to: 'https://www.linkedin.com/in/yannis-panagis/' },
    { link: 'Email', to: 'mailto:yannis.panagis1998@gmail.com' },
    { link: 'Twitter', to: 'https://twitter.com/yannispanagis' },
    {
      link: 'Spotify',
      to: 'https://open.spotify.com/artist/4tCn4Wkn4cf0CWLUFvQO58?si=XUyEXMPASyqdhksoCx587g',
    },
  ]
  return (
    <div className="mx-6 py-8 mt-16 border-t-2 border-gray-600 border-opacity-30">
      <div className="flex flex-col lg:flex-row gap-x-20 gap-y-6">
        {/* ABOUT */}
        <div className="flex flex-col gap-y-2 w-full lg:w-2/5">
          <h6 className="font-bold text-primary text-lg">About</h6>
          <p className="text-primary opacity-60 antialiased">
            I'm Yannis Panagis and I’m a Full Stack Engineer at Behaviour Lab
            and lead the front-end team there. I spend my time building
            products, visualizing data, exploring London, and writing music.
          </p>
        </div>

        <div className="w-full lg:w-3/5 flex justify-left lg:justify-between gap-x-8">
          {/* HELPFUL LINKS */}
          <ul className="flex flex-col flex-wrap gap-y-2">
            <h6 className="font-bold text-primary text-lg">Helpful Links</h6>
            {helpfulLinks.map(({ to, link }) => (
              <Link
                className="text-primary opacity-60 hover:opacity-90 antialiased"
                to={to}
                key={link}
                onMouseEnter={() => soundMode && playPopSound && playPopSound()}
              >
                {link}
              </Link>
            ))}
          </ul>

          {/* CONTACT */}
          <ul className="flex flex-col gap-y-2">
            <h6 className="font-bold text-primary text-lg">Contact</h6>
            {contactLiknks.map(({ link, to }) => (
              <a
                className="text-primary opacity-60 hover:opacity-90 antialiased"
                href={to}
                key={link}
                onMouseEnter={() => soundMode && playPopSound && playPopSound()}
              >
                {link}
              </a>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex flex-col gap-y-2 md:flex-row w-full mt-8 md:justify-between">
        <p className="opacity-60 antialiased text-primary">
          Copyright © 2021 Yannis Panagis
        </p>
        <p className="opacity-60 antialiased text-primary">
          Powered by Coffee. <Emoji label="Coffee" symbol="☕️" />
        </p>
      </div>
    </div>
  )
}

export default Footer
