import * as React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet-async'

interface IPropTypes {
  title: string
  description: string
  lang?: string
}

const SEO = ({ title, description, lang = 'en' }: IPropTypes) => {
  const { site } = useStaticQuery(graphql`
    query SeoQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description

  return (
    <>
      <Helmet
        title={title}
        htmlAttributes={{ lang }}
        titleTemplate={`%s · ${site.siteMetadata.title}`}
        meta={[
          {
            property: `og:title`,
            content: title,
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ]}
      />
    </>
  )
}

export default SEO
