import * as React from 'react'

import { VolumeUpIcon, VolumeOffIcon } from '@heroicons/react/solid'

import { useSoundModeContext } from '../../context/sound-context'

const SoundModeToggle = () => {
  // todo: should remember this based on a cookie in local storage
  const { soundMode, setSoundMode, playCartoonClickOff, playCartoonClickOn } =
    useSoundModeContext()

  function handleClick() {
    console.log(soundMode)
    if (setSoundMode && playCartoonClickOff && playCartoonClickOn) {
      if (soundMode) {
        playCartoonClickOff()
      } else {
        playCartoonClickOn()
      }
      // Toggle sound Mode On/Off
      setSoundMode(!soundMode)
    }
  }

  return (
    <button
      aria-label={!soundMode ? 'Enable Sounds' : 'Disable Sounds'}
      onClick={() => handleClick()}
    >
      {soundMode ? (
        <VolumeUpIcon className="w-6 h-6 text-primary" />
      ) : (
        <VolumeOffIcon className="w-6 h-6 text-primary" />
      )}
    </button>
  )
}

export default SoundModeToggle
