import * as React from 'react'

const AuroraBackground = () => {
  return (
    <div className="bg-primary absolute top-0 left-0 -z-10">
      <div className="absolute top-28 left-36  overflow-visible">
        <div className="opacity-40 blur-[100px] animated-one"></div>
      </div>
      <div className="absolute top-28 left-42 overflow-visible">
        <div className="opacity-40 blur-[100px] animated-three"></div>
      </div>
    </div>
  )
}

export default AuroraBackground
