import * as React from 'react'

import CookieConsent from 'react-cookie-consent'

const CookieNotice = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Yes please!"
      cookieName="yannispanagis-cookie-policy"
      style={{ backgroundColor: '#0369a1' }}
      buttonStyle={{
        backgroundColor: 'white',
        borderRadius: '10px',
        color: 'black',
      }}
      // buttonClasses="bg-white text-black rounded-md px-2 py-2"
      expires={150}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  )
}

export default CookieNotice
